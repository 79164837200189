import React from 'react';
import PropTypes from 'prop-types';

import styles from "./styles.module.scss";
import Heading from "../../../../Heading";
import Img from 'gatsby-image';

const ResultEntry = ({ title, data }) => {
    return (
        <div className={styles.columnEntry}>
            {title && (
                <Heading className={styles.resultHeading} level='h2'>{title}</Heading>
            )}

            {data && (
                !Array.isArray(data)
                    ? (
                        <div className={styles.result}>
                            {data.label}
                            {data.image && (
                                <Img fluid={data.image}/>
                            )}
                        </div>
                    )
                    : (
                        <ul>
                            {data.map((result, index) => (
                                <li key={`${result.id}-${index.toString()}`} className={styles.result}>
                                    {result.label}
                                    {result.image && (
                                        <Img fluid={result.image}/>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )
            )}
        </div>
    );
};

ResultEntry.propTypes = {
    title: PropTypes.string,
    data: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.array,
    ])
};

export default ResultEntry;
