import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

import Heading from "../Heading";
import Container from "../Container";
import TextContent from "../TextContent";
import styles from './styles.module.scss';

const MediaContent = ({ imageFirst, heading, image, children }) => (
    <section className={styles.section}>
        <Container large>
            <div className={styles.container}>
                <div className={imageFirst ? styles.imageFirst : ''}>
                    <Heading className={styles.heading} type="section" level="h2">{heading}</Heading>

                    <TextContent>
                        {children}
                    </TextContent>
                </div>

                {image && (
                    <Img className={styles.image} fluid={image}/>
                )}
            </div>
        </Container>
    </section>
);

MediaContent.defaultProps = {
    image: null,
    imageFirst: false,
};

MediaContent.propTypes = {
    imageFirst: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    image: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
};

export default MediaContent;
