import React, { useState } from 'react';

import Container from "../../Container";
import styles from './styles.module.scss';
import Heading from "../../Heading";
import Button from "../../Button";
import ResultEntry from "./components/ResultEntry";

import primaryJson from "./primary.json";
import secondaryJson from "./secondary.json";
import perk1Json from "./perk1.json";
import perk2Json from "./perk2.json";
import perk3Json from "./perk3.json";
import lethalJson from "./lethal.json";
import tacticalJson from "./tactical.json";
import fieldUpgradeJson from "./field-upgrade.json";
import killStreaksJson from "./kill-streaks.json";
import { graphql, useStaticQuery } from "gatsby";

const CodClassGenerator = () => {
    const images = useStaticQuery(graphql`
        query {
            random: allFile(
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "codclass" }
                }
            ) {
                edges {
                    node {
                        name
                        thumb: childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 350) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        },
                    }
                }
            }
        }
    `);

    const [primaryResult, setPrimaryResult] = useState({ label: 'Random primary weapon' });
    const [secondaryResult, setSecondaryResult] = useState({ label: 'Random secondary weapon' });
    const [perk1Result, setRerk1Result] = useState({ label: 'Random perk 1' });
    const [perk2Result, setRerk2Result] = useState({ label: 'Random perk 2' });
    const [perk3Result, setRerk3Result] = useState({ label: 'Random perk 3' });
    const [lethalResult, setLethalResult] = useState({ label: 'Random lethal' });
    const [tacticalResult, setTacticalResult] = useState({ label: 'Random tactical' });
    const [fieldUpgradeResult, setFieldUpgradeResult] = useState({ label: 'Random field upgrade' });
    const [killStreakResult, setKillStreakResult] = useState({ label: 'Random kill streak' });

    const setResult = (json, setMethod, amount = 1) => {
        const keys = Object.keys(json);
        const keysLength = keys.length;

        if (amount !== 1) {
            const arr = [];

            while (arr.length < amount) {
                const r = Math.floor(Math.random() * keysLength);

                if (arr.indexOf(r) === -1) {
                    arr.push(json[r]);
                }
            }

            setMethod(arr);

            return;
        }

        const randomKey = Math.floor(Math.random() * keysLength);
        const filteredImage = images.random.edges.filter((edge => edge.node.name === json[randomKey].id));

        json[randomKey].image = filteredImage[0] ? filteredImage[0].node.thumb.fluid : null;

        setMethod(json[randomKey]);
    };

    const clickHandler = () => {
        setResult(primaryJson, setPrimaryResult);
        setResult(secondaryJson, setSecondaryResult);
        setResult(perk1Json, setRerk1Result);
        setResult(perk2Json, setRerk2Result);
        setResult(perk3Json, setRerk3Result);
        setResult(lethalJson, setLethalResult);
        setResult(tacticalJson, setTacticalResult);
        setResult(fieldUpgradeJson, setFieldUpgradeResult);
        setResult(killStreaksJson, setKillStreakResult, 3);
    };

    return (
        <div className={styles.background}>
            <Container large>
                <div className={styles.canvas}>
                    <Heading className={styles.pageTitle}>Warzone Random Class Generator</Heading>

                    <div className={styles.buttonContainer}>
                        <Button onClick={clickHandler}>Generate COD Class</Button>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.column}>
                            <ResultEntry title="Primary weapon" data={primaryResult}/>
                        </div>

                        <div className={styles.column}>
                            <ResultEntry title="Secondary weapon" data={secondaryResult}/>
                        </div>

                        <div className={styles.column}>
                            <ResultEntry title="Perk 1" data={perk1Result}/>
                            <ResultEntry title="Perk 2" data={perk2Result}/>
                            <ResultEntry title="Perk 3" data={perk3Result}/>
                        </div>

                        <div className={styles.column}>
                            <ResultEntry title="Lethal" data={lethalResult}/>
                            <ResultEntry title="Tactical" data={tacticalResult}/>
                            <ResultEntry title="Field Upgrade" data={fieldUpgradeResult}/>
                        </div>
                    </div>

                    <div className={styles.center}>
                        <ResultEntry title="Kill streaks" data={killStreakResult}/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CodClassGenerator;
