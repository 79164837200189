import React from 'react';

import GeneralLayout from '../layouts/GeneralLayout';
import SEO from '../components/seo';
import Container from "../components/Container";
import CodClassGenerator from "../components/Generators/CodClassGenerator";
import MediaContent from "../components/MediaContent";
import Heading from "../components/Heading";

const IndexPage = () => {
    return (
        <GeneralLayout>
            <SEO
                title="Warzone Random Class Generator"
                description="Warzone class generator will help Modern Warfare players to challenge themselves by going wild with a randomly generated set of equipment."
            />

            <CodClassGenerator/>

            <Container>
                <MediaContent heading="What is Warzone Class Generator?">
                    <p>
                        The Warzone class generator will help Modern Warfare players to challenge themselves by going wild with a randomly generated set of equipment.
                    </p>
                    <p>
                        The class generator will pick your primary and secondary weapon, the three perks and the lethal, tactical and field upgrade. It will also provide you three
                        kill streaks.
                    </p>
                    <p>Will you generate the best MW class?</p>
                </MediaContent>

                <MediaContent imageFirst heading="Which Warzone perks can be generated?">
                    <Heading level="h3">Perk 1</Heading>
                    <ul>
                        <li>Cold Blooded</li>
                        <li>Double Time</li>
                        <li>E.O.D.</li>
                        <li>Overkill</li>
                        <li>Quick Fix</li>
                        <li>Scavenger</li>
                    </ul>

                    <Heading level="h3">Perk 2</Heading>
                    <ul>
                        <li>Ghost</li>
                        <li>Hardline</li>
                        <li>High Alert</li>
                        <li>Kill Chain</li>
                        <li>Pointman</li>
                        <li>Restock</li>
                    </ul>

                    <Heading level="h3">Perk 3</Heading>
                    <ul>
                        <li>Amped</li>
                        <li>Battle Hardened</li>
                        <li>Shrapnel</li>
                        <li>Spotter</li>
                        <li>Tracker</li>
                        <li>Tune Up</li>
                    </ul>
                </MediaContent>

                <MediaContent heading="Is the Class Generator free to use?">
                    <p>
                        You can use the tool as much as you want. It's 100% free.
                    </p>
                </MediaContent>
            </Container>
        </GeneralLayout>
    );
};

export default IndexPage;
