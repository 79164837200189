import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Button = ({ margin, children, onClick }) => (
    <button className={`${styles.button} ${margin ? styles.margin : ''}`} onClick={onClick} type="button">
        {children}
    </button>
);

Button.defaultProps = {
    margin: false,
    onClick: () => {},
};

Button.propTypes = {
    margin: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

export default Button;
